export default `query sweetDealsItems {
    sweetDealsItems {
        sweetDealsData {
            name
            sku
            discontinued
            typename
            url
            image_url
            regular_price
            special_price
            stock_status
        }
    }
}`;
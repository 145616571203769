

























































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
//import VueTinySlider from 'vue-tiny-slider';
//const tns = () => window && window !== undefined ? import("vue-tiny-slider") : null;
//const tns = () => window && window !== undefined ? import("tiny-slider") : null;
import $ from 'jquery';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'MainBanner',
  components: {
    SkeletonLoader
  },
  props: {
    all_data: {}
  },
  data() {
    return {
      isNuxtReady: false,
    }
  },
  mounted() {
    /* const vm = this;
    vm.isNuxtReady = true;
    $(".owl-prev").empty();
    if ($(".owl-prev")) {
      $( ".owl-prev").append('<i class="fa fa-chevron-left"></i>');
      $( ".owl-next").append('<i class="fa fa-chevron-right"></i>');
    } */
  },
  setup(props) {
    const context = useContext();
    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    var loading = ref(true);

    const initFun = () => {
      console.log("initFun");
      console.log(loading);
      loading.value = false;
      console.log(loading);
    }
    
      const get_slider_by_title = (sliders, title) => {
      var banner = [];
      for (const property in sliders) {
        if (sliders[property]['title'] == title) {
          banner = sliders[property]['banners'];
        }
      }
      return banner;
    };


    /*  var slider = tns({
       container: '.main_banner_slider',
       items: 1,
       slideBy: 'page',
       autoplay: true
     }); */
    /* const prev_slide = () => {
      $('.owl-prev').trigger('click');
    };

    const next_slide = () => {
      $('.owl-next').trigger('click');
    }; */

    const main_slider = [
      {
        "title": "NIKKOR Plena Lens",
        "url": "https://mpex.com/nikon-nikkor-z-135mm-f-1-8-s-plena.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Harman Phoenix 200 Film",
        "url": "https://mpex.com/harman-phoenix-200-color-negative-film-35mm-roll-36-exposures.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "DJI Mic 2",
        "url": "https://mpex.com/catalogsearch/result/?q=Dji-Mic-2-All-Products",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Sony a9 III",
        "url": "https://mpex.com/sony-alpha-a9-iii-mirrorless-digital-camera-body-only.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Canon RF 24-105mm",
        "url": "https://mpex.com/canon-rf-24-105-f-2-8-l-is-usm-z.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Nation-Wide Film Development Now Available",
        "url": "https://www.midwestphotopix.com/film-processing-services",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "MPEX Rentals",
        "url": "https://www.mpexrentals.com/",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "NIKKOR Plena Lens",
        "url": "https://mpex.com/nikon-nikkor-z-135mm-f-1-8-s-plena.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Harman Phoenix 200 Film",
        "url": "https://mpex.com/harman-phoenix-200-color-negative-film-35mm-roll-36-exposures.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "DJI Mic 2",
        "url": "https://mpex.com/catalogsearch/result/?q=Dji-Mic-2-All-Products",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      },
      {
        "title": "Sony a9 III",
        "url": "https://mpex.com/sony-alpha-a9-iii-mirrorless-digital-camera-body-only.html",
        "image_url": magentoBaseUrl+"/media/weltpixel/owlcarouselslider/images/p/l/plena_shopnow2.jpg",
        "__typename": "bannerData"
      }
    ];

    return {
      main_slider,
      get_slider_by_title,
      initFun,
      loading
      /* slider */
      /* prev_slide,
      next_slide */
    };
  },
});

import { defineStore } from 'pinia';
import { ref, onMounted } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import gethomeBannerSection  from '~/components/HomePage/homeBannerSection.gql'

type homeBannerSection = {
  sliders: {},
};
export const useHomeBannerData = defineStore('homeBannerSection', () => {
  const { query } = useApi();
  const sliders = ref<any | null>(null);

  query<homeBannerSection>(gethomeBannerSection).then((response) => {
    sliders.value = response.data;
  });

  return {
    sliders,
  };
});
import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import getnewGearItem  from '~/components/HomePage/newGearItem.gql'

type newGearItems = {
  data: {},
};
export const useNewGearItems = defineStore('newGearItems', () => {
  const { query } = useApi();
  const data = ref<any | null>(null);

  query<newGearItems>(getnewGearItem).then((response) => {
    data.value = response.data;
  });

  return {
    data,
  };
});
export default `
    query homeSection {
        homeSection {
            featured_items {
                tab3 {
                    title
                    data    
                },
                tab4 {
                    title
                    data
                }
            }
            main_categories {
                title
                url
                custom_class        
            }
            hot_categories {
                title
                url
                custom_class        
            }
            sliders {
                title
                banners {
                    title
                    url
                    image_url
                }
            }
            ticker
        }
    }
`;
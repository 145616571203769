import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import getsweetDealsTab  from '~/components/HomePage/sweetDealsTab.gql'

type sweetDealsItems = {
  sweetDealsData: {}
};
export const useSweetDeals = defineStore('sweetDealsItems', () => {
  const { query } = useApi();
  const sweetDealsData = ref<any | null>(null);
  query<sweetDealsItems>(getsweetDealsTab).then((response) => {
    sweetDealsData.value = response.data;
  });
  return {
    sweetDealsData
  }
});
export default `query featuredProcuts($category_id: String!) {
  products(
    filter: {category_id: {eq: $category_id}},
    sort: {name: ASC},
    pageSize: 10,
    currentPage: 1
  ) {
    total_count
    items {
      name
      sku
      url_key
      __typename
      stock_display_status
      discontinued
      special_order
    image {
        url
        label
        position
        disabled
      }
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
          discount {
            amount_off
            percent_off
          }
        }
        maximum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
          discount {
            amount_off
            percent_off
          }
        }
      }
    }
  }
}`;














































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfOverlay, SfHeader, SfButton, SfBadge, SfLink, SfBottomNavigation } from '@storefront-ui/vue';
import { useHomeBannerData } from '~/components/HomePage/homeBanner';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
    name: 'MainBannerDemo',
    props: {

    },
    data() {
        return {
            isNuxtReady: false,
        }
    },
    components: {
        SfLink,
        SkeletonLoader
    },
    setup(props) {
        const homeBannerSection = useHomeBannerData();
        const { sliders } = storeToRefs(homeBannerSection);
        var loading = ref(true);

        const initFun = () => {
        loading.value = false;
        }

        const get_slider_by_title = (slide, title) => {
            var banner = [];
            for (const property in slide) {
                if (slide[property]['title'] == title) {
                banner = slide[property]['banners'];
                }
            }
            return banner;
        };
        return {
            get_slider_by_title,
            initFun,
            loading,
            sliders
        };
    }
});

export default `
    query homeBannerSection {
        homeBannerSection {
            sliders {
                title
                banners {
                    title
                    url
                    image_url
                }
            }
        }
    }
`;
import { ref, onMounted } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import gethomeSection  from '~/components/HomePage/homeSection.gql'


type homepageData = {
    /* new_product_title : [],
    sell_products_title : [],
    main_categories : [],
    new_product : [],
    sell_products: [],
    hot_categories: [], */
    all_data: [],
  };
export const useHomePageData = () => {
  const { query } = useApi();
  const all_data = ref<any | null>(null);
  //const all_data = ref([]);


  onMounted(() => {
    query<homepageData>(gethomeSection)
      .then((response) => {
        all_data.value = response.data;
      });
  });

  return {
    all_data,
    /* new_product_title_,
    sell_products_title_,
    main_categories_,
    new_product_,
    sell_products_,
    hot_categories_, */
  };
};

export default useHomePageData;





























import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'priceBox',
  components: {

  },
  props: {
    special_price : {},
    regular_price : {},
    price_range : {},
    typename : {},
  },
  setup(props) {
    const convert_to_price = (price) => {
        return Intl.NumberFormat('en-EN',  { style: 'currency', currency: 'USD', }).format(price);
    }
    return {
        convert_to_price,
    };
  },
});


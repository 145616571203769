import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import gethomeSection  from '~/components/czar/homeFeaturedProducts/homeFeaturedProducts.gql'
import { useApi } from '~/composables/useApi';

type openBoxItems = {
  usedGearData: {},
  openBoxData:{},
};

export const useOpenBoxByCategory = defineStore('openBoxItems', () => {
  const { query } = useApi();
  const usedGearData = ref<any | null>(null);
  const openBoxData = ref<any | null>(null);

  const fetchUsedGear = async (queryVariables: any) => {
    const response = await query<openBoxItems>(gethomeSection, queryVariables);
    usedGearData.value = response?.data;
    openBoxData.value = response?.data;
  };
  return {
    usedGearData,
    openBoxData,
    fetchUsedGear
  }
});